<template>
  <div class="column column6" data-testid="element_000034">
    <div class="title-category" data-testid="element_000356">
      Connect With Us
    </div>
    <div class="links links-icons" data-testid="element_000035">
      <ui-link
        v-for="social in socialLinksOption"
        :key="social.link"
        :link="{ to: social.link }"
        class="icon-container"
      >
        <ui-icon :name="social.icon" :size="16" />
      </ui-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGlobalStore } from '~/stores'

const globalStore = useGlobalStore()

const socialLinksOption = computed(() => {
  const links = [
    {
      link: globalStore.siteSettings?.linkTwitter || '',
      icon: 'Social/twitter',
      sort: Number(globalStore.siteSettings?.sortTwitter || 100)
    },
    {
      link: globalStore.siteSettings?.linkReddit || '',
      icon: 'Social/reddit',
      sort: Number(globalStore.siteSettings?.sortReddit || 100)
    },
    {
      link: globalStore.siteSettings?.linkYoutube || '',
      icon: 'Social/youtube',
      sort: Number(globalStore.siteSettings?.sortYoutube || 100)
    },
  ]

  return links
    .filter((item) => item.link)
    .sort((a,b) => a.sort - b.sort)
})
</script>

<style lang="scss">

</style>
