<template>
  <layout-default-footer-container :class="`-${$route.name}`" class="footer footer-main">
    <div v-if="menu && menu.length && menu[0]" class="column column2" data-testid="element_000230">
      <div class="title-category" data-testid="element_000243">
        {{ menu[0].name }}
      </div>
      <div class="links" data-testid="element_000018">
        <template v-for="link in menu[0].links" :key="link.id">
          <div
            v-if="applyRule(link)"
            class="link"
            data-testid="element_000354"
          >
            <ui-link :link="{ to: link.link }">
              {{ link.label }}
              <span v-if="link.subtitle" class="subtitle-category" data-testid="element_000019">
                  {{ link.subtitle }}
                </span>
            </ui-link>
          </div>
        </template>
      </div>
    </div>

    <div v-if="menu && menu.length && menu[1]" class="column column3" data-testid="element_000020">
      <div class="column3-1" data-testid="element_000021">
        <div class="title-category" data-testid="element_000022">
          {{ menu[1].name }}
        </div>
        <div class="links" data-testid="element_000023">
          <template v-for="link in menu[1].links" :key="link.id">
            <div
              v-if="applyRule(link)"
              class="link"
              data-testid="element_000024"
            >
              <ui-link :link="{ to: link.link }">
                {{ link.label }}
                <span v-if="link.subtitle" class="subtitle-category" data-testid="element_000019">
                  {{ link.subtitle }}
                </span>
              </ui-link>
            </div>
          </template>
        </div>
      </div>
      <div v-if="menu && menu.length && menu[2]" class="column3-2" data-testid="element_000025">
        <div class="title-category" data-testid="element_000026">
          {{ menu[2].name }}
        </div>
        <div class="links" data-testid="element_000027">
          <template v-for="link in menu[2].links" :key="link.id">
            <div
              v-if="applyRule(link)"
              class="link"
              data-testid="element_000231"
            >
              <ui-link :link="{ to: link.link }">
                {{ link.label }}
                <span v-if="link.subtitle" class="subtitle-category" data-testid="element_000019">
                  {{ link.subtitle }}
                </span>
              </ui-link>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-if="menu && menu.length && menu[3]" class="column column4" data-testid="element_000028">
      <div class="column4-1" data-testid="element_000029">
        <div class="title-category" data-testid="element_000030">
          {{ menu[3].name }}
        </div>
        <div class="links" data-testid="element_000031">
          <template v-for="link in menu[3].links" :key="link.id">
            <div
              v-if="applyRule(link)"
              class="link"
              data-testid="element_000032"
            >
              <ui-link :link="{ to: link.link }">
                {{ link.label }}
                <span v-if="link.subtitle" class="subtitle-category" data-testid="element_000019">
                  {{ link.subtitle }}
                </span>
              </ui-link>
            </div>
          </template>
        </div>
      </div>

      <div class="column4-2" data-testid="element_000033">
        <layout-default-footer-connect-with-us />
      </div>
    </div>
  </layout-default-footer-container>
</template>

<script lang="ts" setup>

const { menu } = useFooterMenu()
const { applyRule } = useCanDisplayItem()
</script>

<style lang="scss">
.footer-main {
  &.-signup,
  &.-login {
    border-top-right-radius: 0;
  }
}
</style>
