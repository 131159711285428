import { useUserStore } from '~/stores/user'

interface IDisplayItemOptions {
  visibilityRule: string[]
  allowDevices: string[]
  deviceVisibility: string[]
}

export default function useCanDisplayItem() {
  const device = useDevice()
  const { width } = useSSRWindowSize()
  const userStore = useUserStore()

  const applyRule = computed(() => {
    return (options: IDisplayItemOptions) => {
      // for visibilityRule data = ['member', 'guest', 'expiredMember']
      const isMember = userStore.isStatus.active || userStore.isStatus.canceled
      const isExpiredMember = userStore.isStatus.expired
      const isGuest = userStore.isRole.guest || userStore.isStatus.inactive
      // for deviceVisibility data = ['mobile', 'desktop']
      let isMobile = 1199 >= width.value
      let isDesktop = !isMobile

      // for allowDevices data = ['android', 'ios']
      const isAndroid = device.isAndroid
      const isIos = device.isIos || device.isApple

      const visibilityMatch = options.visibilityRule.some((rule) => {
        if (rule === 'member' && isMember) return true
        if (rule === 'guest' && isGuest) return true
        if (rule === 'expiredMember' && isExpiredMember) return true
        return false
      })

      const deviceVisibilityMatch = options.deviceVisibility.some(deviceCandidate => {
        if (deviceCandidate === 'mobile' && isMobile) return true
        if (deviceCandidate === 'desktop' && isDesktop) return true
        return false
      })

      if (isDesktop) {
        return visibilityMatch && deviceVisibilityMatch
      }

      const allowDevicesMatch = options.allowDevices.some(device => {
        if (device === 'android' && isAndroid) return true
        if (device === 'ios' && isIos) return true
        return false
      }) || !options.allowDevices.length

      return visibilityMatch && deviceVisibilityMatch && allowDevicesMatch
    }
  })

  return {
    applyRule,
  }
}
