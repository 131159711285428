<template>
  <footer class="footer" data-testid="element_000036">
    <div class="main" data-testid="element_000037">
      <div class="content" data-testid="element_000325">
        <div class="column column1 link" data-testid="element_000038">
          <ui-logo is-footer />
        </div>
        <slot />
      </div>
    </div>
    <div class="logo-rta" data-testid="element_000323">
      <a href="https://www.rtalabel.org/" target="_blank" title="RTA">
        <svg width="76" height="26" viewBox="0 0 76 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <use href="/svg/logo-rta.svg#logo-rta" />
        </svg>
      </a>
      <div class="text stl-text-14wb400v2" data-testid="element_000324">{{ copyright }}</div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useGlobalStore } from '~/stores'

const globalStore = useGlobalStore()

const copyright = computed(() => {
  return globalStore.withVariable(globalStore.siteSettings?.footerCopyright)
})
</script>

<style lang="scss">

</style>
