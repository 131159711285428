import { useGlobalStore } from '~/stores'
import groupBy from 'lodash-es/groupBy'
import type { FooterMenu } from "~/shared/types/digest/digest"

export default function useFooterMenu() {
  const globalStore = useGlobalStore()

  const menuTypes = globalStore.digest.footerMenuTypes
  const linkGroup = groupBy(globalStore.digest.footerMenu, 'type')

  type TGroup = {
    key: number
    name: string
    isExpanded: boolean
    links: FooterMenu[]
  }

  const menu = ref<TGroup[]>([])

  if (menuTypes?.length) {
    for (const menuType of menuTypes) {
      const links: FooterMenu[] = []

      for (const linkItem of linkGroup[menuType.key].sort((a, b) => a.sort - b.sort)) {
        links.push(linkItem)
      }

      menu.value.push({
        key: menuType.key,
        name: menuType.label,
        isExpanded: menuType.key === 1,
        links,
      })
    }
  }

  const changeCategoryVisible = (index: number) => {
    menu.value[index].isExpanded = !menu.value[index].isExpanded
  }

  const categoryArrowClasses = computed(() => {
    return (isExpanded: boolean) => {
      return [
        'svg-arrow',
        isExpanded ? 'svg-arrow_up' : '',
      ]
    }
  })

  return {
    menu,
    changeCategoryVisible,
    categoryArrowClasses,
  }
}
